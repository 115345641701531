import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import Layout from "../../components/layout";
import Quote from "../../components/quote";
import {navigate} from "gatsby";


import patternHex from "../../images/backrounds/pattern-hex-white.jpg";
import InfoContactUsForm from "../../components/info-contact-us-form";

const subscriberLpNewToCxFormTag = process.env.GATSBY_SUBSCRIBER_LP_NEW_TO_CX_FORM_TAG


const Hero = () => (
  <div
    className="hero mt-0"
    style={{
      backgroundImage: `url(${patternHex})`,
    }}
  >
    <div className="hero-title bg-dark-transparent">
      <div className="hero-image">
        <StaticImage
          src="../images/clients/zendesk.png"
          alt="Zendesk Implementation Premier Partner"
          layout="constrained"
          style={{ maxWidth: "200px"}}
        />
      </div>
      <h1 className="text-white">
        Make It Easy To Understand What’s Going On With Your Customer Support
      </h1>
      <p className="text-white">
        Our clients tell us over and over how Zendesk makes supporting their customers simple…
      </p>
      <h3 className="mt-0">
        And We Want To Make Your Help Desk Easy To Run, Too.
      </h3>


      <AnchorLink to="#get-started" className="btn btn-primary">Get Started</AnchorLink>
    </div>
  </div>
);

const Main = ({location}) => (
  <section className="pt-6" id="get-started">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-6 col-lg-8 mb-6 mb-md-0">
          <h2>
            When You Sign Up For Zendesk Through 729 Solutions, You Can Get Up To 30% Discount On Seats.
          </h2>
          <h5>Why?</h5>
          <h5>Because we’re a Zendesk Premier Partner, AMER Partner of the Year, and Master Implementer. Pretty fancy
            titles, huh? Our moms think so, too...</h5>
          <p>But what that means is that we can offer our customers a discount that keeps paying off every month.</p>
          <h3>So You Get A System That Really Works.</h3>
          <p>And you can actually USE it because you easily understand how to get it to do what you need.</p>
        </div>
        <div className="col-12 col-md-6 col-lg-4 bg-light py-4">
          <h3 className="text-center">
            Want An Easy, Simple Customer Service Platform? At A Lower Price?
          </h3>
          <div>
            <InfoContactUsForm
              buttonText="Improve my customer service"
              tags={[subscriberLpNewToCxFormTag]}
              location={location}
              onSubmitSuccess={(() => navigate('/lp/new-to-cx-ty'))}/>
          </div>
        </div>
      </div>
    </div>
  </section>
);

const Trends = () => (
  <section className="pt-6">
    <div className="container">
      <div className="row row-cols-1 row-cols-md-3">
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">On average, Zendesk increases customer satisfaction by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">25%*</p>
            </div>
          </div>
        </div>
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">On average, Zendesk increases agent satisfaction by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">30%*</p>
            </div>
          </div>
        </div>
        <div className="col mb-4 mb-lg-0">
          <div className="card custom-cards card-border h-100">
            <div className="card-body pb-0">
              <p className="mb-0">Customers will spend more to buy from a company that offers good customer service
                by</p>
            </div>
            <div className="card-footer bg-transparent border-0">
              <p className="h1 mb-4">75%*</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row pt-3">
        <div className="col">
          <p>* CX Trends Report 2021</p>
        </div>
      </div>
    </div>
  </section>
);

const Quotes = () => (
  <section className="pt-6">
    <div className="container">
      <div className="row text-center">
        <div className="col">
          <h3>So... Would You Like To Have More Customers?<br/>Who Are Happier? </h3>
          <h2>Here’s What A Few Users Have Said<br/>About Their Zendesk Experiences:</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Quote
            quote="&quot;It helps our teams maintain the personalization that our clients need while quickly solving their questions or help requests. The best part?
            Our customers feel genuinely supported and heard.&quot;"
          />
        </div>
        <div className="col-lg-6">
          <Quote
            quote="&quot;The majority of our customer emails, voicemails, and recorded calls are logged in Zendesk so
            our support team loves how simple it is to find and respond to customer requests,
            what they have contacted us about, and who helped them. Zendesk also makes metrics tracking extremely easy with pre-fab dashboards that are quickly customizable..&quot;"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Quote
            quote="&quot;The reporting tab is all I need for keeping track of how the team is doing.
            I don’t need to export the information to another tool to get numbers, they’re all in this same platform.&quot;"
          />
        </div>
        <div className="col-lg-6">
          <Quote
            quote="&quot;With the overhead in delegation and our tricky queue needs,
            we found Zendesk to be the answer to our administrative woes.
            Not to mention our answer when it came to a tech support agent taking a well-deserved day off without worrying about their email coverage.&quot;
            "
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6">
          <Quote
            quote="&quot;Zendesk makes it super easy to have specific teams stay connected with each other;
            not just the support team.&quot;"
          />
        </div>
        <div className="col-lg-6">
          <Quote
            quote="&quot;Our technical and purchase support teams faced the challenge of increasing support requests as our company grew. It wasn’t manageable or logical to respond to customers through a Gmail alias anymore.
            Zendesk helped us scale.&quot;
            "
          />
        </div>
      </div>
    </div>
  </section>
);

const Index = ({location}) => (
  <Layout>
    <Hero/>
    <Main location={location}/>
    <Trends/>
    <Quotes/>
  </Layout>
);

export default Index;
